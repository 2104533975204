<template>
	<el-dialog width="680px" :title="employee.name + ' 薪酬配置'" :visible.sync="configs_dialog" append-to-body destroy-on-close>
		<template v-if="show_table">
			<el-table style="margin-top: -30px;" class="scroll-wrapper" height="47vh" :data="configs" :size="theme.size">
				<el-table-column label="名称" prop="name" min-width="120">
					<el-link slot-scope="scope" type="primary" @click="$refs['create'].open(scope.row)">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="类型" prop="salary_field.name" min-width="80"></el-table-column>
				<el-table-column label="基数" min-width="60" :formatter="({amount}) => { return +amount; }"></el-table-column>
				<el-table-column label="方式" min-width="50" :formatter="({appoint}) => { return appoint ? '指定' : '出勤'; }"></el-table-column>
				<el-table-column label="结算系数" min-width="80" :formatter="({appoint, coefficient, salary_field}) => { return appoint ? (coefficient * 100)+'% '+salary_field_types[salary_field.type] : '实出 / 应出'; }"></el-table-column>
					<el-table-column label="结算周期" min-width="80" :formatter="({period_at}) => { return period_at || '每月固定'; }"></el-table-column>
				<el-table-column label="备注说明" prop="commit" min-width="120" show-overflow-tooltip></el-table-column>
				<el-table-column label="操作" min-width="50">
					<el-link slot="header" type="primary" size="mini" @click="$refs['create'].open({employee_id: employee.id})">添加</el-link>
					<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)">删除</el-link>
				</el-table-column>
			</el-table>
			<el-pagination
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getConfigs({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getConfigs({ ...params, page: v }) }"
				background>
			</el-pagination>
		</template>
		<template v-else>
			<el-result icon="warning" title="警告提示" subTitle="暂不支持为员工设置薪酬配置，请前往薪酬列表初始化薪酬配置字段"></el-result>
		</template>
		<create ref="create" :employees="employees" :fields="fields" @refresh="getConfigs(params)" selected></create>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import create from '@/pages/hrm/salaries/config/create';

	export default {
		components: {
			create
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open (employee) {
				this.getConfigs({ employee_id: employee.id }, true);
				this.employee = employee;
				this.configs_dialog = true;
			},
			delRow ({id, name}) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleConfig({action: 'delete', id});
				});
			},
			async handleConfig (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getConfigs(this.params);
				this.$message.success(msg);
				this.$emit('refresh');
			},
			async getConfigs (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.configs = result.data;
				this.fields = result.fields;
				this.employees = result.employees;
				this.show_table = (result.fields && result.fields.length);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				employee: {},
				employees: [],
				fields: [],
				configs: [],
				salary_field_types: {1: '发放', 2: '扣除'},
				configs_dialog: false,
				show_table: true,
				params: {
					perPage: 10
				}
			}
		}
	};
</script>