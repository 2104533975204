<template>
	<el-dialog :title="config.id ? '编辑薪酬' : '添加薪酬'" width="380px" :visible.sync="config_dialog" append-to-body destroy-on-close>
		<el-form label-width="80px" ref="configs_form" :model="config" :rules="rules" :size="theme.size" status-icon>
			<el-form-item label="员工" prop="employee_id" v-if="!config.id">
				<el-select v-model="config.employee_id" placeholder="员工" :disabled="selected" filterable clearable>
					<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="名称" prop="name">
				<el-input v-model="config.name" placeholder="请输入名称" clearable>
					<el-select slot="prepend" style="width: 90px;" v-model="config.salary_field_id" placeholder="类型" filterable clearable>
						<el-option v-for="field in fields" :key="field.id" :label="field.name" :value="field.id"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item label="基数" prop="amount">
				<el-input-number placeholder="基数" v-model="config.amount" :precision="2" :min="0.01"></el-input-number>
			</el-form-item>
			<el-form-item label="结算方式" prop="appoint">
				<el-switch v-model="config.appoint" :active-value="1" :inactive-value="0" active-text="指定" inactive-text="出勤"></el-switch>
			</el-form-item>
			<el-form-item label="结算系数" prop="coefficient" v-if="config.appoint">
				<el-input-number placeholder="结算系数" v-model="config.coefficient" :precision="2" :step="0.01" :min="0.01" :max="1"></el-input-number>
			</el-form-item>
			<el-form-item label="结算周期" prop="period_at">
				<el-date-picker type="month" value-format="yyyy-MM" placeholder="结算周期。为空时每月固定" v-model="config.period_at" :picker-options="pickerOptions" :editable="false"></el-date-picker>
			</el-form-item>
			<el-form-item label="备注说明" prop="commit">
				<el-input v-model="config.commit" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('configs_form')">{{config.id ? '提交保存'  : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			employees: {
				type: Array
			},
			fields: {
				type: Array
			},
			selected: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open (config = {}) {
				this.config = {...config};
				this.config_dialog = true;
			},
			async submitConfig (data) {
				const res = await this.$http.post(this.$api.URI_SALARIES_CONFIGS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.config_dialog = false;
						this.$emit('refresh');
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'configs_form':
							this.submitConfig(this.config);
						break;
					}
				});
			}
		},
		data() {
			return {
				config: {},
				config_dialog: false,
				rules: {
					employee_id: [{ required: true, message: '请选择员工', trigger: 'blur' }],
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					amount: [{ required: true, message: '请输入基数', trigger: 'blur' }],
					coefficient: [{ required: true, message: '请输入系数', trigger: 'blur' }]
				},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth > currentMonth);
					}
				}
			}
		}
	};
</script>