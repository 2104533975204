<template>
	<el-dialog width="420px" :title="contract.id ? '合同详情' : '添加合同'" :visible.sync="contract_dialog" @closed="contract = {}" append-to-body destroy-on-close>
		<div class="pr" style="margin-top: -20px;" v-if="is_show">
			<el-descriptions :labelStyle="{width: '70px'}" :column="2" :size="theme.size" border>
				<el-descriptions-item label="系统编号">{{contract.no}}</el-descriptions-item>
				<el-descriptions-item label="类型">{{e_contract_types[contract.type] || '未知'}}</el-descriptions-item>
				<el-descriptions-item label="自编编号">{{contract.out_no}}</el-descriptions-item>
				<el-descriptions-item label="状态">{{e_contract_statuses[contract.status] || '未知'}}</el-descriptions-item>
				<el-descriptions-item label="工作地点">{{contract.work_location}}</el-descriptions-item>
				<el-descriptions-item label="任职岗位">{{contract.position}}</el-descriptions-item>
				<el-descriptions-item label="签订日期">{{contract.sign_at}}</el-descriptions-item>
				<el-descriptions-item label="生效日期">{{contract.effective_at}}</el-descriptions-item>
				<el-descriptions-item label="试用期限">{{contract.probation_at || '无试用期'}}</el-descriptions-item>
				<el-descriptions-item label="到期日期">{{contract.expiration_at || '无固定期限'}}</el-descriptions-item>
				<el-descriptions-item label="创建日期">{{contract.created_at}}</el-descriptions-item>
				<el-descriptions-item label="终止原因" :span="2">{{contract.termination_reason || '-'}}</el-descriptions-item>
				<el-descriptions-item label="相关附件" :span="2" v-if="contract.attachments && contract.attachments.length">
					<div v-for="attachment in contract.attachments" :key="attachment.id">
						<el-link type="primary" @click="openFile(attachment.url)">{{attachment.name}}</el-link>
					</div>
				</el-descriptions-item>
			</el-descriptions>
			<div class="mt10">
				<el-button type="primary" size="mini" @click="contract.id = 0;">更新合同</el-button>
			</div>
		</div>
		<el-form label-width="80px" ref="contract_form" :model="contract" :rules="contract_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="纸质编号" prop="out_no">
				<el-input v-model="contract.out_no" placeholder="请输入纸质编号" clearable></el-input>
			</el-form-item>
			<el-form-item label="工作地点" prop="work_location">
				<el-input v-model="contract.work_location" placeholder="请输入工作地点" clearable></el-input>
			</el-form-item>
			<el-form-item label="任职岗位" prop="position">
				<el-input v-model="contract.position" placeholder="请输入任职岗位" clearable></el-input>
			</el-form-item>
			<el-form-item label="合同类型" prop="type">
				<el-select v-model="contract.type" @change="changeType" placeholder="请选择合同类型" filterable>
					<el-option v-for="(type, t) in e_contract_types" :key="t" :label="type" :value="+t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="薪资结构" style="margin-bottom: 0;" required>
				<el-form-item :prop="`salary_structure.${f}`" v-for="(ff, f) in contract.salary_structure" :key="f" :rules="[{ required: true, message: '填写基本工资', trigger: 'blur'}]">
					<el-input-number v-model="contract.salary_structure[f]" :min="0.01" :precision="2" placeholder="基本工资"></el-input-number>
					<!-- <el-button type="text" class="ml10" v-if="f == 'basic'">添加</el-button>
					<el-button type="text" class="ml10" v-else>删除</el-button> -->
				</el-form-item>
			</el-form-item>
			<el-form-item label="签订日期" prop="sign_at">
				<el-date-picker type="date" v-model="contract.sign_at" value-format="yyyy-MM-dd" :editable="false" @change="changeSign" placeholder="请选择签订日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="生效日期" prop="effective_at">
				<el-date-picker type="date" v-model="contract.effective_at" value-format="yyyy-MM-dd" :editable="false" placeholder="请选择生效日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="试用期限" prop="probation_at">
				<el-date-picker type="date" v-model="contract.probation_at" value-format="yyyy-MM-dd" :editable="false" placeholder="请选择试用期限。无试用期期限可为空"></el-date-picker>
			</el-form-item>
			<el-form-item label="到期日期" prop="expiration_at" v-if="contract.type != 2" :rules="[{ required: contract.type != 2, message: '必须选择到期日期', trigger: 'blur'}]">
				<el-date-picker type="date" v-model="contract.expiration_at" value-format="yyyy-MM-dd" :editable="false" placeholder="请选择到期日期。无固定期限可为空" :picker-options="{disabledDate (time) { return time.getTime() < Date.now(); }}"></el-date-picker>
			</el-form-item>
			<el-form-item label="相关附件" prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: contracts_cfg.type}"
					:accept="contracts_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-remove="(f, l) => { contract = {...contract, attachments: l}; }"
					:file-list="contract.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{contracts_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{contracts_cfg.format_size_unit}}<br>劳动、劳务等纸质签字扫描文件</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('contract_form')">确认提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';

	export default {
		computed: {
			...mapState(['theme', 'models']),
			contracts_cfg () {
				return this.$utils.uploadConfig('employment_contracts');
			},
			e_contract_dict () {
				if (!this.models) return {};
				return this.models.employment_contracts || {};
			},
			e_contract_types () {
				return this.e_contract_dict.types || {};
			},
			e_contract_statuses () {
				return this.e_contract_dict.statuses || {};
			},
			is_show () {
				if (this.contract.id) return true;
				return false;
			}
		},
		props: {
			status: {
				type: Array
			}
		},
		methods: {
			async open (d = {}) {
				this.contract_dialog = true;
				this.contract = {
					salary_structure: { basic: 2550 },
					type: 1,
					...d
				}
			},
			openFile (u) {
				window.open(u);
			},
			changeType (e) {
				if (e == 2) {
					this.contract = { ...this.contract, expiration_at: '' };
				}
			},
			changeSign (e) {
				const { effective_at, type } = this.contract;
				if (!effective_at) {
					this.contract = { ...this.contract, effective_at: e };
				}
				if (type != 2) {
					this.contract = { ...this.contract, expiration_at: moment(e).add(3, 'years').format('Y-MM-DD') };
				}
				this.contract = { ...this.contract, probation_at: moment(e).add(6, 'months').format('Y-MM-DD') };
			},
			onSuccess (list) {
				this.contract = {
					...this.contract,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			async submitcontract (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES_CONTRACTS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.contract_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'contract_form':
							this.submitcontract(this.contract);
						break;
					}
				});
			}
		},
		data() {
			return {
				contract: {
					salary_structure: {}
				},
				contract_dialog: false,
				contract_rules: {
					work_location: [{ required: true, message: '必须填写工作地点', trigger: 'blur'}],
					position: [{ required: true, message: '必须填写任职岗位', trigger: 'blur'}],
					type: [{ required: true, message: '必须选择合同类型', trigger: 'blur'}],
					sign_at: [{ required: true, message: '必须选择签订日期', trigger: 'blur'}],
					effective_at: [{ required: true, message: '必须选择生效日期', trigger: 'blur'}]
				}
			}
		}
	};
</script>