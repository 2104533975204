<template>
	<el-dialog width="860px" :fullscreen="theme.width < 860" :title="employee.name + ' 基金保险'" :visible.sync="welfares_dialog" append-to-body destroy-on-close>
		<el-table ref="insurance_table" class="scroll-wrapper" height="480px" :data="insurances" :size="theme.size" @sort-change="sortChange" show-summary>
			<el-table-column prop="name" label="名称" min-width="120">
				<template slot-scope="scope">
					<el-link type="primary" :disabled="!$utils.update($api.URI_INSURANCES)" @click="$refs['insurances'].open(scope.row)">{{scope.row.name}}</el-link>
					<p class="fs12 c9">{{scope.row.remarks}}</p>
				</template>
			</el-table-column>
			<el-table-column label="缴费" width="50" :formatter="({once}) => { return once ? '当月' : '每月'; }"></el-table-column>
			<el-table-column label="基数" prop="base" width="90" sortable="custom" :formatter="({base}) => { return (base*1) + ' 元'}"></el-table-column>
			<el-table-column label="缴纳比例金额" width="140">
				<template slot-scope="scope">
					<p>公司 {{(scope.row.ent*1) + ' %'}} {{(scope.row.ent_amount*1) + ' 元'}}</p>
					<p>个人 {{(scope.row.ind*1) + ' %'}} {{(scope.row.ind_amount*1) + ' 元'}}</p>
				</template>
			</el-table-column>
			<el-table-column label="小计" prop="sub_amount" width="80" :formatter="({sub_amount}) => { return (sub_amount*1) + ' 元'}"></el-table-column>
			<el-table-column label="操作时间" width="190">
				<template slot-scope="scope">
					<p v-if="scope.row.expired_at">失效于 {{scope.row.expired_at}}</p>
					<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
					<p v-if="scope.row.created_at">创建于 {{scope.row.created_at}}</p>
				</template>
			</el-table-column>
			<el-table-column width="50">
				<el-link slot="header" type="primary" size="mini" :disabled="!$utils.create($api.URI_INSURANCES)" @click="$refs['insurances'].open({employee_id: employee.id, employee })">添加</el-link>
				<template slot-scope="scope">
					<el-button type="text" :disabled="!$utils.delete($api.URI_INSURANCES)" @click="delInsurance(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
					<el-button type="text" :disabled="!$utils.restore($api.URI_INSURANCES)" @click="resInsurance(scope.row)" v-else>恢复</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getInsurances({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getInsurances({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<create-insurances ref="insurances" :temps="temps" @refresh="getInsurances(params)"></create-insurances>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import createInsurances from '@/pages/hrm/insurances/create';

	export default {
		components: {
			createInsurances,
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open (employee) {
				this.getInsurances({ employee_id: employee.id }, true);
				this.employee = employee;
				this.welfares_dialog = true;
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInsurances(this.params);
			},
			delInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'delete', id});
				});
			},
			resInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要恢复吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'restore', id});
				});
			},
			async getInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.insurances = result.data;
				this.temps = result.temps;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleInsurances (params) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getInsurances(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				employee: {},
				insurance: {},
				insurances: [],
				employees: [],
				temps: [],
				welfares_dialog: false,
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getInsurances(this.params, true);
		}
	};
</script>